import React from "react"
import { Link } from "gatsby"
import Colors from "../../../constants/Colors"
import { CONTACT, LANDING } from "../../../constants/routes"
import {
  Person,
  AccountCircle,
  PersonAdd,
  Group,
  BlurCircular,
} from "@material-ui/icons"
import Divider from "../../atoms/Divider"

const Structure = () => {
  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">Share Bibles Structure</h1>
      </div>
      <div className="ministry__divider">
        <Divider />
      </div>
      <div>
        <div className="ministry__details">
          <h2 className="ministry__title">Types of Users</h2>
        </div>
        <div className="types_of_users">
          <div className="user_type">
            <Person
              style={{ color: Colors.primary, fontSize: 50, margin: "0 auto" }}
            />
            <div className="user_label">Users</div>
            <div className="user_description">
              General users are the people who are actively distributing Bibles
              location by location. In order for users to upload their data to
              the Share Bibles database they will need to be a part of a team.
            </div>
          </div>
          <div className="user_type">
            <AccountCircle
              style={{ color: Colors.primary, fontSize: 50, margin: "0 auto" }}
            />
            <div className="user_label">Team Leaders</div>
            <div className="user_description">
              Team Leaders are users with the ability to invite users to join
              their team. This action is done directly through the Share Bibles
              app.
            </div>
          </div>
          <div className="user_type">
            <PersonAdd
              style={{ color: Colors.primary, fontSize: 50, margin: "0 auto" }}
            />
            <div className="user_label">Facilitators</div>
            <div className="user_description">
              Facilitators are Bible distribution ministry leaders or staff on
              the ground. They facilitate the long-term distribution plans in a
              given location. Facilitators manage their ministry information and
              create teams by inviting team leaders to create Share Bibles
              accounts.
            </div>
          </div>
        </div>
      </div>
      <div className="ministry__divider">
        <Divider />
      </div>
      <div>
        <div className="ministry__details">
          <h2 className="ministry__title">Types of Groups</h2>
        </div>
        <div className="types_of_users">
          <div className="group_type">
            <Group
              style={{ color: Colors.primary, fontSize: 50, margin: "0 auto" }}
            />
            <div className="user_label">Teams</div>
            <div className="user_description">
              Teams are groups of users that distribute Bibles together. This
              could be used for a group of long-term staff, short-term teams and
              local churches. Teams are created by Facilitators. All the
              distribution data that is uploaded will be associated with a team.
              The team will be able to see its own distribution report inside
              the Share Bibles App.
            </div>
          </div>
          <div className="group_type">
            <BlurCircular
              style={{ color: Colors.primary, fontSize: 50, margin: "0 auto" }}
            />
            <div className="user_label">Ministries</div>
            <div className="user_description">
              Bible Distribution ministries are managed by Facilitators.
              Whenever a facilitator creates a team, that team's distribution
              data will be attributed to the ministry and Facilitators can view
              a distribution report. Ministries can have multiple facilitators
              and create as many teams as they need.
            </div>
          </div>
        </div>
      </div>
      <div className="ministry__divider">
        <Divider />
      </div>
      <div style={{ marginBottom: 100 }}>
        <div className="ministry__details">
          <h2 className="ministry__title">How it works</h2>
        </div>
        <div className="H_I_W_user_group">
          <h3 className="H_I_W_user_group_label">For Facilitators:</h3>
          <ol className="H_I_W_user_group_description">
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                CREATE FACILITATOR ACCOUNT
              </div>
              <div className="H_I_W_list_subitem">
                <span className="H_I_W_list_subtitle">New Ministries:</span>{" "}
                Facilitator accounts can be requested by contacting the Share
                Bibles Team through <Link to={CONTACT}>the contact form</Link>
              </div>
              <div className="H_I_W_list_subitem">
                <span className="H_I_W_list_subtitle">
                  Existing Ministries:
                </span>{" "}
                Facilitator accounts can be created by another facilitator in
                that same ministry. Contact the current facilitator to request a
                facilitator account within the existing ministry.
              </div>
            </li>
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                CREATE TEAMS
              </div>
              <div className="H_I_W_list_subitem">
                Facilitators can create teams for groups of users who will be
                distributing Bibles with the facilitator's ministry.
              </div>
            </li>
          </ol>
        </div>
        <div className="H_I_W_user_group">
          <h3 className="H_I_W_user_group_label">For Team Leaders:</h3>
          <ol className="H_I_W_user_group_description">
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                FACILITATOR INVITES THE TEAM LEADER
              </div>
              <div className="H_I_W_list_subitem">
                Only Facilitators can create new teams. A potential team leader
                must be invited by a Facilitator in order to form a new team.
                The Facilitator will invite the team leader by email. Check your
                spam folder if you do not see an email from Share Bibles. Click
                the link in the email to create an account.
              </div>
            </li>
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                TEAM LEADER INVITES TEAM MEMBERS
              </div>
              <div className="H_I_W_list_subitem">
                Once the team leader has a Share Bibles account the team leader
                can log into the Share Bibles app and invite team members
                through email.
              </div>
            </li>
          </ol>
        </div>
        <div className="H_I_W_user_group">
          <h3 className="H_I_W_user_group_label">For Users:</h3>
          <ol className="H_I_W_user_group_description">
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                CREATE YOUR ACCOUNT
              </div>
              <div className="H_I_W_list_subitem">
                <span className="H_I_W_list_subtitle">I have a team:</span> Your
                team leader must invite you through the Share Bibles app by
                email. Check your spam folder if you do not see an email from
                Share Bibles. Click the link in the email to create an account.
              </div>
              <div className="H_I_W_list_subitem">
                <span className="H_I_W_list_subtitle">
                  I don't have a team:
                </span>{" "}
                Contact the facilitator of the ministry that you are
                distributing Bibles with and ask to join an existing team or
                form a new team. If there is no Share Bibles ministry in your
                location, please contact the Share Bibles team through{" "}
                <Link to={CONTACT}>the contact form</Link>.
              </div>
            </li>
            <li>
              <div className="H_I_W_list_subitem H_I_W_user_group_label">
                LOG IN AND RECORD DATA
              </div>
              <div className="H_I_W_list_subitem">
                Use the email and password that you chose when you created your
                account to log in to the Share Bibles App. Instructions
                regarding how to use the app can be found in the video on the{" "}
                <Link to={LANDING}>home page</Link>.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Structure
